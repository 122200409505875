/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 991px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #222;
  }
  .wrapper form {
    background-color: white;
    border: 3px solid grey;
    width: 80vw;
    border-radius: 3px;
  }
  .formInnerContainer {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .form div {
    line-height: 2em;
    margin-left: 5px;
    /* margin-top: 5px; */
  }
  .form div input {
    padding: 3px 5px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .form div div label {
    display: block;
  }

  .form button {
    padding: 4px 8px;
    float: right;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .center {
    margin: auto;
    width: 50%;
    padding: 12px;
  }

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #222;
  }
  .wrapper form {
    background-color: white;
    border: 3px solid grey;
    height: auto;
    width: 80vw;
    margin: 10px auto;
    padding: 20px;
    border-radius: 3px;
  }
  .formInnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .formHalf1 {
    width: 30%;
  }
  .formHalf2 {
    width: 30%;
  }
  .form div {
    line-height: 2em;
    margin-top: 5px;
  }
  .form div input {
    padding: 3px 5px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .form div div label {
    display: block;
  }

  .form button {
    padding: 4px 8px;
    float: right;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .wrapper form {
    background-color: white;
    border: 3px solid grey;
    height: auto;
    width: 60vw;
    margin: 10px auto;
    padding: 20px;
    border-radius: 3px;
  }
}
