.redirectDiv {
  width: 80%;
  padding: 10px;
  color: #0236d1;
  text-align: center;
}

.redirectButton {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  cursor: pointer;
}
