.response-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .response-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #222;
  }
  .response-innerContainer {
    background-color: white;
    border:3px solid grey;
    height:50vh;
    width:30vw;
    margin: 10px auto;
    padding: 20px;
    border-radius: 3px;
  }