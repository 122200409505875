.div {
  width: 50%;
  padding: 10px;
  color: #0236d1;
  font-size: 16px;
  text-align: center;
  background-color: whitesmoke;
}

.center {
  text-align: center;
  color: #0236d1;
}

.li {
  color: #0236d1;
}

.text-left {
  text-align: left;
}

.button {
  font-size: 18px;
  padding: 14px 40px;
  float: right;
  background-color: #0236d1;
  color: white;
}
